// Inspiration for this class goes to Matt DesLauriers @mattdesl,
// really awesome dude, give him a follow!
// https://github.com/mattdesl/threejs-app/blob/master/src/webgl/WebGLApp.js
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FlyControls } from 'three/examples/jsm/controls/FlyControls.js'
import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls.js'
import { Raycaster } from 'three'
import createTouches from 'touches'
import dataURIToBlob from 'datauritoblob'
import Stats from 'stats.js'
import State from 'controls-state'
import wrapGUI from 'controls-gui'
import { getGPUTier } from 'detect-gpu'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import CannonDebugRenderer from './CannonDebugRenderer'

export default class WebGLApp {
  #updateListeners = []
  #tmpTarget = new THREE.Vector3()
  #lastTime
  #width
  #height

  constructor({
    background = 'transparent',
    backgroundAlpha = 1,
    fov = 45,
    near = 0.01,
    far = 100,
    ...options
  } = {}) {
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      // enabled for saving screenshots of the canvas,
      // may wish to disable this for perf reasons
      preserveDrawingBuffer: true,
      failIfMajorPerformanceCaveat: true,
      ...options,
    })

    this.renderer.sortObjects = false
    this.canvas = this.renderer.domElement

    this.renderer.setClearColor(background, backgroundAlpha)

    if (options.xr) {
      this.renderer.xr.enabled = true
    }

    // save the fixed dimensions
    this.#width = options.width
    this.#height = options.height


    this.mouseX = 0;
    this.mouseY = 0;

    this.mouse = new THREE.Vector2();

    this.intersected;

    this.windowHalfX = options.width ? options.width / 2 : window.innerWidth / 2;
    this.windowHalfY = options.height ? options.height / 2 : window.innerHeight / 2;

    // clamp pixel ratio for performance
    this.maxPixelRatio = options.maxPixelRatio || 2
    // clamp delta to stepping anything too far forward
    this.maxDeltaTime = options.maxDeltaTime || 1 / 30

    // setup a basic camera
    this.camera = new THREE.PerspectiveCamera(fov, 1, near, far)

    this.scene = new THREE.Scene()



    this.initialScene = this.scene

    this.gl = this.renderer.getContext()

    this.time = 0
    this.isRunning = false
    this.#lastTime = performance.now()

    // handle resize events
    window.addEventListener('resize', this.resize)
    window.addEventListener('orientationchange', this.resize)

    // force an initial resize event
    this.resize()

    // __________________________ADDONS__________________________

    // really basic touch handler that propagates through the scene
    this.touchHandler = createTouches(this.canvas, {
      target: this.canvas,
      filtered: true,
    })
    this.isDragging = false
    this.touchHandler.on('start', (ev, pos) => {
      this.isDragging = true
      this.traverse('onPointerDown', ev, pos)
    })
    this.touchHandler.on('move', (ev, pos) => this.traverse('onPointerMove', ev, pos))
    this.touchHandler.on('end', (ev, pos) => {
      this.isDragging = false
      this.traverse('onPointerUp', ev, pos)
    })

    // expose a composer for postprocessing passes
    if (options.postprocessing) {
      this.composer = new EffectComposer(this.renderer)
      this.composer.addPass(new RenderPass(this.scene, this.camera))
    }

    // set up a simple orbit controller
    if (options.orbitControls) {

      this.orbitControls = new OrbitControls(this.camera, this.canvas)
    }

    if (options.moveCam) {
      this.moveCam = options.moveCam
    }

    if (options.raycaster) {
      this.raycaster = new Raycaster()
    }

    // Attach the Cannon physics engine
    if (options.world) {
      this.world = options.world
      if (options.showWorldWireframes) {
        this.cannonDebugRenderer = new CannonDebugRenderer(this.scene, this.world)
      }
    }

    // show the fps meter
    if (options.showFps) {
      this.stats = new Stats()
      this.stats.showPanel(0)
      document.body.appendChild(this.stats.dom)
    }

    // initialize the controls-state
    if (options.controls) {
      const controlsState = State(options.controls)
      this.controls = options.hideControls
        ? controlsState
        : wrapGUI(controlsState, { expanded: !options.closeControls })

      // add the custom controls-gui styles
      if (!options.hideControls) {
        const styles = `
          [class^="controlPanel-"] [class*="__field"]::before {
            content: initial !important;
          }
          [class^="controlPanel-"] [class*="__labelText"] {
            text-indent: 6px !important;
          }
          [class^="controlPanel-"] [class*="__field--button"] > button::before {
            content: initial !important;
          }
        `
        const style = document.createElement('style')
        style.type = 'text/css'
        style.innerHTML = styles
        document.head.appendChild(style)
      }
    }

    // detect the gpu info
    const gpu = getGPUTier({ glContext: this.renderer.getContext() })
    this.gpu = {
      name: gpu.type,
      tier: Number(gpu.tier.slice(-1)),
      isMobile: gpu.tier.toLowerCase().includes('mobile'),
    }
  }

  get width() {
    return this.#width || window.innerWidth
  }

  get height() {
    return this.#height || window.innerHeight
  }

  get pixelRatio() {
    return Math.min(this.maxPixelRatio, window.devicePixelRatio)
  }

  resize = ({ width = this.width, height = this.height, pixelRatio = this.pixelRatio } = {}) => {

    this.windowHalfX = width / 2;
    this.windowHalfY = height / 2;

    // update pixel ratio if necessary
    if (this.renderer.getPixelRatio() !== pixelRatio) {
      this.renderer.setPixelRatio(pixelRatio)
    }

    // setup new size & update camera aspect if necessary
    this.renderer.setSize(width, height)
    if (this.camera.isPerspectiveCamera) {
      this.camera.aspect = width / height
    }
    this.camera.updateProjectionMatrix()

    // resize also the composer
    if (this.composer) {
      this.composer.setSize(pixelRatio * width, pixelRatio * height)
    }

    // recursively tell all child objects to resize
    this.scene.traverse((obj) => {
      if (typeof obj.resize === 'function') {
        obj.resize({
          width,
          height,
          pixelRatio,
        })
      }
    })

    // draw a frame to ensure the new size has been registered visually
    this.draw()
    return this
  }

  // convenience function to trigger a PNG download of the canvas
  saveScreenshot = ({ width = 2560, height = 1440, fileName = 'image.png' } = {}) => {
    // force a specific output size
    this.resize({ width, height, pixelRatio: 1 })
    this.draw()

    const dataURI = this.canvas.toDataURL('image/png')

    // reset to default size
    this.resize()
    this.draw()

    // save
    saveDataURI(fileName, dataURI)
  }

  update = (dt, time, xrframe) => {
    if (this.orbitControls) {
      this.orbitControls.update()
    }

    if (this.moveCam) {
      this.camera.position.x += ( this.mouseX - this.camera.position.x ) * 0.00005;
      this.camera.position.y += ( - this.mouseY - this.camera.position.y ) * 0.00005;
    }

    if (this.raycaster) {
      // update the picking ray with the camera and mouse position
      this.raycaster.setFromCamera( this.mouse, this.camera );
      this.raycaster.firstHitOnly = true

      // calculate objects intersecting the picking ray


      const userObject = this.scene.children.find((child) => !!child.gglab)

      const intersects = this.raycaster.intersectObjects( [...userObject.children[0].children[0].children, userObject.children[0].children[1]] );

      if ( intersects.length > 0 && this.mouse.x != 0 && this.mouse.y != 0 ) {
          if ( this.intersected ) this.intersected.material.emissive.setHex( this.intersected.currentHex );
          const defaultObject = userObject.children[0].children[0].children[0]
          const intersectedObject = intersects.find((intersect) => intersect.object.geometry.type === 'BufferGeometry') || []

          this.intersected = intersectedObject.length
            ? intersectedObject.object
            : defaultObject;

          this.intersected.currentScale = this.intersected.parent.scale
          this.intersected.currentHex = this.intersected.material.emissive.getHex();

          this.intersected.material.emissive.setHex( 0xff0000 );
          this.intersected.parent.scale.set(1.1,1.1,1.1)

          this.renderer.setClearColor( 0xffffff, 0);
          document.querySelector('#text').classList.add('text__main--intersected')

      } else {
        document.querySelector('#text').classList.remove('text__main--intersected')
        this.renderer.setClearColor( 0x000000, 1);
        if ( this.intersected ) {
          this.intersected.material.emissive.setHex( this.intersected.currentHex );
          this.intersected.parent.scale.set(1,1,1)
        }

        this.intersected = null;

      }
    }

    // recursively tell all child objects to update
    this.scene.traverse((obj) => {
      if (typeof obj.update === 'function') {
        obj.update(dt, time, xrframe)
      }
    })

    if (this.world) {
      // update the Cannon physics engine
      this.world.step(1 / 60, dt)

      // update the debug wireframe renderer
      if (this.cannonDebugRenderer) {
        this.cannonDebugRenderer.update()
      }

      // recursively tell all child bodies to update
      this.world.bodies.forEach((body) => {
        if (typeof body.update === 'function') {
          body.update(dt, time)
        }
      })
    }

    // call the update listeners
    this.#updateListeners.forEach((fn) => fn(dt, time, xrframe))

    return this
  }

  onUpdate(fn) {
    this.#updateListeners.push(fn)
  }

  offUpdate(fn) {
    const index = this.#updateListeners.indexOf(fn)

    // return silently if the function can't be found
    if (index === -1) {
      return
    }

    this.#updateListeners.splice(index, 1)
  }

  draw = () => {

    if (this.composer) {
      // make sure to always render the last pass
      this.composer.passes.forEach((pass, i, passes) => {
        const isLastElement = i === passes.length - 1

        if (isLastElement) {
          pass.renderToScreen = true
        } else {
          pass.renderToScreen = false
        }
      })

      this.composer.render()
    } else {
      this.renderer.render(this.scene, this.camera)
    }
    return this
  }

  start = () => {
    if (this.isRunning) return
    this.renderer.setAnimationLoop(this.animate)
    this.isRunning = true
    return this
  }

  stop = () => {
    if (!this.isRunning) return
    this.renderer.setAnimationLoop(null)
    this.isRunning = false
    return this
  }

  animate = (now, xrframe) => {
    if (!this.isRunning) return

    if (this.stats) this.stats.begin()

    const dt = Math.min(this.maxDeltaTime, (now - this.#lastTime) / 1000)
    this.time += dt
    this.#lastTime = now
    this.update(dt, this.time, xrframe)
    this.draw()

    if (this.stats) this.stats.end()
  }

  traverse = (fn, ...args) => {
    this.scene.traverse((child) => {
      if (typeof child[fn] === 'function') {
        child[fn].apply(child, args)
      }
    })
  }

  get cursor() {
    return this.canvas.style.cursor
  }

  set cursor(cursor) {
    if (cursor) {
      this.canvas.style.cursor = cursor
    } else {
      this.canvas.style.cursor = null
    }
  }
}

function saveDataURI(name, dataURI) {
  const blob = dataURIToBlob(dataURI)

  // force download
  const link = document.createElement('a')
  link.download = name
  link.href = window.URL.createObjectURL(blob)
  link.onclick = setTimeout(() => {
    window.URL.revokeObjectURL(blob)
    link.removeAttribute('href')
  }, 0)

  link.click()
}
