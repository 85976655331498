export const fetchDailyTrends = () => {
  return fetch('/gtrands/data.json')
    .then(response => response.json())
    .then((data) => {
      const trendingSearchesDays = data.default.trendingSearchesDays
      const trends = trendingSearchesDays
        .map(day => day.trendingSearches)
        .reduce((resultTrends, curr) => [...resultTrends, ...curr], [])
      return trends
  })
}

export const dailyTrendsMapper = (dailyTrend) => dailyTrend.title.query + ' '

export const dailyText = (dailyTrendsArray) =>
  dailyTrendsArray
    .map(dailyTrendsMapper)
    .join('')
    .repeat(1000)
